import React, {useEffect, useState} from 'react';
import useAuth from "./useAuth";

const useFormatDate = (date) =>  {
    const [formattedDate, setFormattedDate] = useState(new Date(date));
    const {timezone, dateLocale} = useAuth();

    useEffect(() => {

        if ( date instanceof Date ) { //TODO: to remove
            date && setFormattedDate( new Date(date).toLocaleString(dateLocale, {timeZone: timezone}))
        }

        return () => {

        };
    });
    
    const newDate = new Date(formattedDate).toLocaleDateString('default', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        //timeZone: 'Europe/Rome', // Specifica il fuso orario
    })

    const newTime = new Date(formattedDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    //return formattedDate.toString();
    return (`${newDate} ${newTime}`)
};

export default useFormatDate;
